export const uiConfig = {
    apiURL: "https://eqcsandboxapi.freyraim.com",
    //apiURL: "https://eqcdevapi.freyraim.com",
    //apiURL: "https://eqcapi.freyraim.com",
    //apiURL: "https://eqceuapi.freyraim.com",
    //apiURL: "https://eqcnfrapi.freyraim.com",
    //apiURL: "http://localhost:1818",
    //apiURL:  "http://localhost:2030",
    defaultRegion: "EU (Central)",
    defaultCountry: "European Union (Centralised)"
  };
  