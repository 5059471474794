import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Button from "react-bootstrap/Button";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { uiConfig } from './config';
import download_icon from '../styles/images/download_icon.png';
import axios from 'axios';
import loader from "./../styles/images/loader_1.gif";
import Moment from 'react-moment';

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total margin-left-10">
    Showing {from} to {to} of {size} Results
  </span>
);
let docTypeFilter;
const docTypeOptions = {
  PPI: "PPI",
  IFU: "IFU",
  MG: "MG",
  RG: "RG",
  USPI: "USPI",
};

let statusFilter;
const statusOptions = {
  "In-Queue": "In-Queue",
  "In-Progress": "In-Progress",
  Completed: "Completed",
  "Exception Occured": "Exception",
};

let regionFilter;
const regionOptions = {
  US: "US",
  LATAM: "LATAM",
  "EU (Central)": "EU (Central)",
  "EU (Non-Central)": "EU (Non-Central)",
  "Middle East & Africa": "Middle East & Africa",
  JAPAC: "JAPAC",
};


export const QC_RequestList = () => {
  debugger;
  const [todos, set_qc_lists] = useState([]);

  const [showGrid, setShowGrid] = useState(false)

  // bootstrap table options
  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "15",
        value: 15,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: todos.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  // table columns
  const columns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
    },

    {
      dataField: "task_id",
      text: "Task ID",
      filter: textFilter(),
    },
    {
      dataField: "region_name",
      text: "Region",
      //filter: textFilter()
      filter: selectFilter({
        options: regionOptions,
        getFilter: (filter) => {
          regionFilter = filter;
        },
      }),
    },
    {
      dataField: "country_name",
      text: "Country",
      filter: textFilter(),
    },
    {
      dataField: "doc_type",
      text: "Doc Type",
      width:500,
      filter: selectFilter({
        options: docTypeOptions,
        getFilter: (filter) => {
          docTypeFilter = filter;
        },
      }),
    },
    {
      dataField: "file_name",
      text: "File Name",
      filter: textFilter(),
      formatter: annotationFormatter,
    },
    {
      dataField: "created_by",
      text: "Requested By",
      filter: textFilter(),
      //formatter: annotationFormatter,
    },
    {
      dataField: "created_date",
      text: "Created Date",
      filter: textFilter(),
      formatter: createdDateFormatter,
      sort: true,
    },
    {
      dataField: "status_name",
      text: "Status",
      className:"xxx",
      //filter: textFilter()
      filter: selectFilter({
        options: statusOptions,
        getFilter: (filter) => {
          statusFilter = filter;
        },
      }),
    },
    {
      dataField: "file_name",
      text: "Output",
      //filter: textFilter(),
      formatter: output_formatter,
    },
    // {
    //   dataField: '',
    //   text: 'Audit',
    //   filter: textFilter(),
    //   formatter: buttonFormatter,
    // }
  ];

  const change_request_status = async (row) => {
    debugger;
    console.log(row)
    const formData = new FormData();
    debugger;
    formData.append("_id", row._id);
    const options = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(
      uiConfig.apiURL + "/rerun_request",
      options
    );
    const responseMessage = await response.json();
    fetchTodos();
    
  }

  function output_formatter(cell, row) {
    if(row["status_name"] == "Completed")
    {
    return (
      <a href={row["output_file_path"]} target="_blank">
        <img src={download_icon}  alt="download" width='33px' height='33px'/>
      </a>
    );
    }
    else{
      return (<label></label>);
      // return (
      //   <Button
      //     variant={row["status_name"] != 'Complted' ? "primary" : "secondary"}
      //     onClick={() => {
      //       change_request_status(row);
      //     }}
      //     className="margin-bottom-10"
      //   >
      //     Re-Run
      //   </Button>
      // );
    }

    //return <button variant="primary" href='#' target="_blank" className="margin-bottom-10" > {row['enabled'] == true? "Enabled":"Disabled"}</button >;
  }
  
  // initial data load
  useEffect(() => {
    fetchTodos();
  }, []);

  const fetchTodos = async () => {
    bind_requests()
  };

  // overriding the dom element to display button
  function buttonFormatter(cell, row) {
    return (
      <Button
        variant="primary"
        onClick={() => {
          //showFilesList(row);
        }}
        className="margin-bottom-10"
      >
        Audit
      </Button>
    );
  }

  function annotationFormatter(cell, row) {
    return (
      <a
        variant="primary"
        target="_blank"
        href={row["input_file_path_web"]}
        className="margin-bottom-10"
      >
        {" "}
        {row["file_name"]}
      </a>
    );
  }

  const bind_requests = () => {
    debugger;
    setShowGrid(false)
    axios.get(uiConfig.apiURL + "/get_all_requests")
    .then( (response) => {
      debugger;
      console.log("response", response);
      set_qc_lists(response.data.data);
      setShowGrid(true)
    })
    .catch( (error) => {
      console.log(error);
    }); 
  }; 

  function createdDateFormatter(cell, row) {
    //alert()
    debugger;
    const new_date = row["created_date"]
    return <Moment locale="de" format="DD-MMM-YYYY hh:mm:ss A">{new_date}</Moment>
    ;
  }

  const load_requests = async () => {
    debugger;
    bind_requests()
  };

  return (
    <>

      <div className="card border-0" >
        <div className="card-body shadow-sm">
        
        <button type="button" class="btn btn-info btn-circle refresh-btn" onClick={load_requests}><i class="fa fa-check"></i> Refresh</button>
          <div>
            <BootstrapTable
              keyField="ID"
              data={todos}
              columns={columns}
              pagination={paginationFactory(options)}
              filter={filterFactory()}
              defaultSorted={[{
                dataField: 'created_date',
                order: 'desc'
              }]}
            />
          </div>
        </div>
      </div>
      
      <div  style={{display: showGrid ? 'none' : 'block' }} className="loader_demo" ><img src={loader}></img></div>
    </>
  );
};
