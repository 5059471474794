import { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Toast } from "react-bootstrap";
import { uiConfig } from "./config";

const ValidateEQCTakeda = (account) => {
  const user_details = account.account.account[0];

  const [task_id, setInput] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const inputRef = useRef(null);
  const [showToast, setShowToast] = useState(false);

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const clear_form = async () => {
    setInput("");
    setUploadedFileName("");
  };

  const validate_eqc = async () => {
    const formData = new FormData();

    formData.append("file", inputRef.current.files[0]);
    formData.append("task_id", task_id);
    formData.append("username", user_details.name);
    formData.append("user_email", user_details.username);

    const options = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(
      uiConfig.apiURL +"/validate_eqc",
      options
    );
    const responseMessage = await response.json();
    clear_form();
    setShowToast(true);
  };

  const handleDisplayFileDetails = () => {
    inputRef.current?.files &&
      setUploadedFileName(inputRef.current.files[0].name);
  };

  return (
    <>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
      >
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Success</strong>
        </Toast.Header>
        <Toast.Body>Request has been added successfully</Toast.Body>
      </Toast>
      <label className="mx-3">English QC Automation</label>
      <Card>
        <Card.Body>
          <label className="mb-2">Task ID: </label>
          <br />
          <input value={task_id} onInput={(e) => setInput(e.target.value)} />
          <br />
          <br />
          <label className="mb-2">Choose File:</label>
          <input
            ref={inputRef}
            onChange={handleDisplayFileDetails}
            className="d-none"
            type="file"
            accept=".docx"
          />
          <div></div>
          <button
            onClick={handleUpload}
            className={`btn btn-outline-${
              uploadedFileName ? "success" : "primary"
            }`}
          >
            {uploadedFileName ? uploadedFileName : "Upload"}
          </button>
          <br />
          <br />
          <br />
          <Button
            as="input"
            type="submit"
            value="Submit"
            onClick={validate_eqc}
          />
          {"      "}
          <Button
            as="input"
            type="reset"
            value="Clear"
            onClick={clear_form}
          />{" "}
        </Card.Body>
      </Card>
    </>
  );
};

export default ValidateEQCTakeda;
